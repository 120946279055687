import React from 'react';

export default ({
    shortAddress,
    suburb,
    className = '',
}: {
    shortAddress: string | null;
    suburb: string;
    className?: string;
}) => {
    const address = shortAddress ? `${shortAddress}, ${suburb}` : `${suburb}`;
    return <span className={className}>{address}</span>;
};
