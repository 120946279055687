/* @flow */

import React from 'react';

type Props = {
    propertyType: string,
    className: string,
};

export default ({ propertyType, className }: Props) => (
    <span className={className} role="text" aria-label={`${propertyType} property type`}>
        {propertyType}
    </span>
);
