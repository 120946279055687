// @flow
import { IS_SERVER } from '../../config';
import { currentLayout, LAYOUT_DEFINITION } from '../../client/device-capability';
import { startPrebidWhenIframeLoaded } from '@media/ad-kit';
import { createDetailPagePrebidConfig, createResultPagePrebidConfig } from '@media/advert-configs-plw';
import { APP_NAME } from './adParams';
import type { GetTrackingData } from '../../models/lexa';

const startPrebid = (dataWithGetters: GetTrackingData, isPdp: boolean = false): void => {
    if (IS_SERVER) {
        return;
    }

    const layout = currentLayout();
    const isSmallOrMediumScreen = layout === LAYOUT_DEFINITION.S || layout === LAYOUT_DEFINITION.M;

    const dataLayer = dataWithGetters.getTrackingData();

    const prebidConfig = isPdp
        ? createDetailPagePrebidConfig(isSmallOrMediumScreen)
        : createResultPagePrebidConfig(isSmallOrMediumScreen);

    startPrebidWhenIframeLoaded(prebidConfig, APP_NAME, dataLayer);
};

export default startPrebid;
