/* @flow */
import DepthTypes from './DepthTypes';
import type { ProductDepth } from './DepthTypes';

export type DepthConfiguration = {
    agentPhotoStyle: string,
    brandingStyle: string,
    compressedView: boolean,
    displayCarousel: boolean,
    imageStyle: string,
};

const ProductDepthConfiguration: { [x: string]: DepthConfiguration } = {};
ProductDepthConfiguration[DepthTypes.PREMIERE] = {
    brandingStyle: 'large',
    imageStyle: 'large',
    agentPhotoStyle: 'image',
    compressedView: false,
    displayCarousel: true,
};

ProductDepthConfiguration[DepthTypes.HIGHLIGHT] = {
    brandingStyle: 'medium',
    imageStyle: 'medium',
    agentPhotoStyle: 'text',
    compressedView: false,
    displayCarousel: true,
};

ProductDepthConfiguration[DepthTypes.FEATURE] = {
    brandingStyle: 'small',
    agentPhotoStyle: 'none',
    imageStyle: 'small',
    compressedView: true,
    displayCarousel: true,
};

ProductDepthConfiguration[DepthTypes.STANDARD] = {
    brandingStyle: 'extra-small',
    agentPhotoStyle: 'none',
    imageStyle: 'extra-small',
    compressedView: true,
    displayCarousel: true,
};

export function getProductDepthConfiguration(productDepth: ProductDepth = DepthTypes.STANDARD): DepthConfiguration {
    return ProductDepthConfiguration[productDepth];
}
