/* @flow */

import React from 'react';
import adaptLexaData from './adaptLexaData';
import type { DataType } from './adaptLexaData';
import View from './View';

const PropertyType = ({ data, className }: { data: DataType, className: string }) => {
    return <View {...adaptLexaData(data)} className={className} />;
};

export default PropertyType;
