/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing } from '../../models/lexa/index';
import type { LexaPropertyType } from '../../models/lexa/index';
import PropertyCardPropertyType from './PropertyCardPropertyType.graphql';

export type DataType = GetResidentialListing;

type AdaptedData = { propertyType: string };

const adapter = ({ propertyType }: { propertyType: LexaPropertyType }): AdaptedData => ({
    propertyType: propertyType.display,
});

const adaptLexaData: (DataType) => AdaptedData = compose(
    adapter,
    getFragmentData(PropertyCardPropertyType),
    (data: GetResidentialListing) => data.getListing()
);

export default adaptLexaData;
