/* @flow */
import type { BuyResidentialListing } from '../lexa';
import getResidenceSchemaMarkup from './getResidenceSchemaMarkup';
import type { EventSchemaMarkup } from './types';

export default (buyResidentialListing: BuyResidentialListing): ?EventSchemaMarkup => {
    const { address, auction, _links } = buyResidentialListing;
    if (auction && address.display.shortAddress) {
        const residenceMarkup = getResidenceSchemaMarkup(buyResidentialListing);
        return residenceMarkup
            ? {
                  '@context': 'http://schema.org',
                  '@type': 'Event',
                  location: residenceMarkup,
                  name: 'Auction',
                  startDate: auction.dateTime.value,
                  url: _links.canonical.href,
              }
            : null;
    }

    return null;
};
