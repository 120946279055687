import { compose3 as compose } from '../../../util/functional/typescript/compose';
import getFragmentData from '../../../util/typescript/getFragmentData';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../models/Address';
import type { GetResidentialListing, Address, ResidentialListings } from '../../../../types';
import ResidentialListingCardAddress from './ResidentialListingCardAddress.graphql';

type AdapterProps = {
    address: Address;
};

type AdaptedData = {
    suburb: string;
    shortAddress: string;
};

const dataAdapter = ({ address }: AdapterProps) => ({
    suburb: address.suburb,
    shortAddress: address.display.shortAddress || DEFAULT_DISPLAY_ADDRESS,
});

const adaptLexaData = compose<GetResidentialListing, ResidentialListings, AdapterProps, AdaptedData>(
    dataAdapter,
    getFragmentData(ResidentialListingCardAddress),
    (data: GetResidentialListing) => data.getListing()
);

export default adaptLexaData;
