import { compose3 as compose } from '../../../util/functional/typescript/compose';
import getFragmentData from '../../../util/typescript/getFragmentData';
import type { GetProjectProfile, Address, ProjectProfile } from '../../../../types';
import ProjectProfileCardAddress from './ProjectProfileCardAddress.graphql';

type AdapterProps = {
    address: Address;
};

type AdaptedData = {
    suburb: string;
    shortAddress: string | null;
};

const dataAdapter = ({ address }: AdapterProps): AdaptedData => {
    return {
        suburb: address.suburb,
        shortAddress: address.display.shortAddress ? address.display.shortAddress : null,
    };
};

const adaptLexaData = compose<GetProjectProfile, ProjectProfile, AdapterProps, AdaptedData>(
    dataAdapter,
    getFragmentData(ProjectProfileCardAddress),
    (data: GetProjectProfile) => data.getListing()
);

export default adaptLexaData;
