/* @flow */
import type { BuyResidentialListing, RentResidentialListing } from '../lexa';
import getResidenceSchemaMarkup from './getResidenceSchemaMarkup';
import type { EventSchemaMarkup } from './types';

export default (residentialListing: BuyResidentialListing | RentResidentialListing): Array<EventSchemaMarkup> => {
    const { inspections, _links } = residentialListing;
    if (inspections.length >= 1) {
        const residenceMarkup = getResidenceSchemaMarkup(residentialListing);
        return residenceMarkup
            ? inspections.map((inspection) => ({
                  '@context': 'http://schema.org',
                  '@type': 'Event',
                  location: residenceMarkup,
                  name: 'Inspection',
                  startDate: inspection.startTime,
                  endDate: inspection.endTime,
                  url: _links.canonical.href,
              }))
            : [];
    }

    return [];
};
