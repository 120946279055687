import React from 'react';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import View from './View';
import type { GetResidentialListing, GetProjectProfile } from '../../../../types';

type ResidentialListingProps = {
    data: GetResidentialListing;
    className?: string;
};

type ProjectProfileProps = {
    data: GetProjectProfile;
    className?: string;
};

export const ResidentialListingAddress = ({ data, className }: ResidentialListingProps) => (
    <View className={className} {...adaptResidentialListingData(data)} />
);

export const ProjectProfileAddress = ({ data, className }: ProjectProfileProps) => (
    <View {...adaptProjectProfileData(data)} className={className} />
);
